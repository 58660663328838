<template>
<!-- eslint-disable -->
	<div>
		<h1 class="text-center my-6">ネタの宝箱</h1>
		<v-textarea
			outlined
			name="report"
			label="人生というパレットに何を描くかは君の自由だ"
			v-model="content"
			height="300px"
			style="width:300px; margin: auto;"
			v-on:keydown.enter="enterDown()"
			v-bind:rules="upRules"
        ></v-textarea>
		<v-row style="max-width: 100% !important; margin: 0;">
			<v-btn
				class="mx-auto mt-4"
				color="secondary"
				@click="upload()"
				style="width:150px;"
			>
				保存しておく
			</v-btn>
		</v-row>
	</div>
</template>

<script>
/* eslint-disable */
import * as api from '@/api.js'
  export default {
	name: 'Memo',
    data () {
      return {
	// 投稿
		menu: false,
		content: '',
		upRules: [text => text.length <= 595 || '最大文字数は595文字です'],

      }
    },
	methods: {
		load(){
			this.content = ''
			api.memo.get().then((res) => {
                switch(res.data.status) {
                    case 200:
                        this.content = res.data.content
                        break
                    case 500:
						this.$router.push({ name: 'Home' })
                        break
                }
			}).catch()
		},

		upload(){
			api.memo.upload({content: this.content}).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop('', 'アップロードが成功しました！')
                        break
                    case 500:
						this.pop('アップロードが失敗しました', '※空の投稿はできません')
                        break
                }
			}).catch()
		},
		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},


		enterDown() {
			return this.content.replace(/\\n/g,'\n')
		}
	},
	created() {
		this.load()
	},
  }
</script>
<style>
.gradation{
    background: linear-gradient(to left top, rgba(204, 204, 204, 0.3) 15%, white 5%, white 85%, rgba(204, 204, 204, 0.3) 10%);
}
.v-dialog > .v-card > .v-card__actions {
	margin: auto;
	max-width: 300px;
}
</style>